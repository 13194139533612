let alternativeUrls: Ref<Record<string, string>>

export function useAlternativePageUrls() {
  const i18n = useI18n()

  if (!alternativeUrls) {
    alternativeUrls = ref({} as Record<string, string>)
  }

  return {
    urls: computed(() => alternativeUrls.value),
    setupUrls(cb: (locale: string) => string) {
      alternativeUrls.value = i18n.localeCodes.value.reduce(
        (acc, code) => {
          return {
            ...acc,
            [code]: cb(code)
          }
        },
        {} as Record<string, string>
      )
    }
  }
}
